import './App.css';

const URL = "https://apps.heartfulness.org/events-tracker";
setTimeout(function() {
    window.location.href = URL;
}, 8000);

function App() {
  return (
    <div className="App">
      <img alt="Banner" src="photo.png" width="100%" />
      <h2>Heartfulness Event Summary Reporting</h2>
       <div className="overlay"></div>
         <div className="popup-message">
             <p>This application is no longer in use. Please click the below URL or you will be automatically redirected in 10 seconds.</p>
             <p>URL: <strong><a href={URL}>{URL}</a></strong></p>
         </div>
    </div>
  );
}

export default App;
